import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';

import { areasRepository } from 'repositories/Area/AreasRepository';
import { isOk } from 'domains/Result';
import { fireToast } from 'helpers/Toasts';

import { useLocationContext } from 'features/location/Location.context';
import { useLocationShared } from 'features/location/hooks/LocationShared/LocationShared.hook';

const useLocationAutocomplete = () => {
  const { setIsLoading, setAutocompleteOptions, setAreas, searchInput } =
    useLocationContext();

  const { clearAreaList } = useLocationShared();

  const getAreasAutocomplete = async (term: string) => {
    setIsLoading(true);
    const areas = await areasRepository.getAreasAutocomplete(term);
    if (isOk(areas)) {
      setAutocompleteOptions(areas.ok);
    } else {
      fireToast({
        type: 'ERROR',
        text: 'Oops! Something went wrong, please try again later',
      });
      setIsLoading(false);
    }
  };

  useOnUpdateOnly(() => {
    switch (searchInput.length) {
      case 0:
        clearAreaList();
        return;
      case 1:
      case 2: {
        setAreas([]);
        return;
      }
      default:
        getAreasAutocomplete(searchInput);
        return;
    }
  }, [searchInput]);

  return {
    getAreasAutocomplete,
  };
};

export { useLocationAutocomplete };
